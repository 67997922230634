.log-in-form-below-frog {
  display: flex;
  background-color: rgb(140, 105, 246);
  flex-direction: column;
  align-items: center;
  color: white;
  height: 300px;
  margin-top: -10px;
}

.login-button {
  height: 30px;
  font-size: 2rem;
  background-color: red;
}

.demo-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  /* height: 50px; */
  border: none;
  border-radius: 5px;
  height: 35px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 5px;
}

.loginform-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  height: 48px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 5px;
}

.demo-sign-in-button:hover,
.loginform-sign-in-button:hover {
  background-color: #00b8ff;
}

.signup-link-in-login-form {
  justify-content: space-between;
}

.form-log-in {
  display: flex;
  flex-direction: column;
  color: white;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
}

.email-and-password-form {
  background-color: rgb(211, 176, 244);
  border-radius: .5rem;
}

.error-messages-login > .error-message {
  font-weight: bold;
}

.login-button {
  height: 30px;
  padding: 0px;
  font-size: 1rem;
}

.log-in-form-below-frog {
  display: flex;
  background-color: rgb(140, 105, 246);
  flex-direction: column;
  align-items: center;
  color: white;
  height: 300px;
  margin-top: -10px;
}

/* .demo-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  height: 48px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 5px;

} */

.loginform-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  /* height: 52px; */
  height: 35px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: .1rem;
  margin-left: 5px;
}

.signup-link-in-login-form {
  justify-content: space-between;

}

.form-log-in {
  display: flex;
  flex-direction: column;
  color: white;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;

}

.email-and-password-form {
  background-color: rgb(211, 176, 244);
  border-radius: .5rem;
}



.sign-up-button {
  color: white
}

.loginform-sign-in-button .hello {
  color: white;
}

.hi {
  background-color: reds;
}
