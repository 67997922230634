z#root {
  background-color: #0a1b40;
}

.Feed {
  max-width: 35rem;
  margin: 0 auto;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-left: auto;
}

.posts {
  margin-top: 1rem;
  width: 100%;
}

.loading {
  margin-top: 12rem;
  margin-left: -7rem;
}

.post {
  background-color: #fff;
  border: 1px solid #dddfe2;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.AA-Text {
  text-decoration: none;
}

/* Reset the list styles and remove default button styles */
.post-types {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  box-sizing: border-box;
  height: 85px;
  width: 560px;
  background-color: white;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  border-radius: 5px;
}

.post-type-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #fff;
}

.post-type-btn:focus {
  outline: none;
}

.text-btn {
  color: #35465c;
  display: flex;
  flex-direction: column;
}

.photo-btn {
  color: #fc4c4c;
  display: flex;
  flex-direction: column;
}

.quote-btn {
  color: #ff8b4e;
  display: flex;
  flex-direction: column;
}

.link-btn {
  color: #3cb878;
  display: flex;
  flex-direction: column;
}

.chat-btn {
  color: #3a81c3;
  display: flex;
  flex-direction: column;
}

.audio-btn {
  color: #a07dfc;
  display: flex;
  flex-direction: column;
}

.video-btn {
  color: #e1306c;
  display: flex;
  flex-direction: column;
}

.post-type-icon {
  margin-right: 8px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.following-foryou-tags-manage {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: transparent;
  margin-left: 22px;
  margin-bottom: -32px;
}

.following-foryou-tags-manage a {
  margin: 0 8px;
  color: rgb(46, 166, 226);
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 17px;
}

.following-foryou-tags-manage a:hover {
  text-decoration: underline;
}

.create-please {
  background: transparent;
  border: none;
}

.create-footer,
.photo-footer,
.quote-footer,
.link-footer,
.chat-footer,
.sound-footer,
.video-footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.photo-footer {
  color: rgb(211, 58, 58);
}

.quote-footer {
  color: orange;
}

.chat-footer {
  color: blue;
}

.sound-footer {
  color: rgb(151, 118, 182);
}

.link-footer {
  color: rgb(72, 195, 127);
}

.video-footer {
  color: rgb(224, 66, 245);
}

.follow-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}