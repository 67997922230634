/* input:valid+label {
    color: #ff5a5f;
}

input:focus {
    outline: none;
} */


.delete-post-title {
    font-weight: bold
}

.form-div {
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
}
input {
    width: 100%;
    height: 45px;
    border: none;
    background-color: #fafafa;
    padding: 0 10px;
    font-size: 14px;
}
.delete-post-submit-button {
    background-color: #f40857;
    width: 12rem;
    height: 2.5rem;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
}

.delete-post-submit-button:hover {
    background-color:#c20349;
}



.delete-post-cancel-button {
    /* width: 132px;
    width: 100%;
    height: 50px; */
    width: 12rem;
    height: 2.5rem;
    background-color: rgb(152, 151, 151);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    margin: 10px;
}

.delete-post-cancel-button:hover {
    background-color: rgb(110, 110, 110);
}
