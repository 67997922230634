.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.post-header img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-item-postTitle {
  font-weight: bold;
  font-size: 1.6rem;
}

.post-item-postOwner-username {
  font-weight: 500;
}

.post-header .username {
  font-weight: bold;
  margin-right: 10px;
}

.post-header .timestamp {
  color: #666;
}

.comment-text-bubble {
  overflow-wrap: break-word;
}

.comment-owner {
  font-weight: bold;
}

.post-content {
  margin-bottom: 10px;
  overflow-wrap: break-word;
  font-size: 1.2rem;
}

.post-footer {
  display: flex;
  align-items: center;
}

.myList {
  display: none;
}

.post-footer button {
  border: none;
  background: none;
  font-weight: bold;
  color: #999;
  margin-right: 10px;
}

.post-footer button:hover {
  cursor: pointer;
  color: #333;
}

.hidden {
  display: none;
}

.post-option {
  box-sizing: border-box;
  background: white;
  min-height: 100px;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 5px;
  display: flex;
  position: relative;
  font-size: 30px;
}

.post-option ul {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex
}

.post-option ul li {
  width: 100%;
  list-style-type: none
}

.post-option ul svg {
  margin-bottom: 5px
}

.text-area-for-comments {
  display: block;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
  background-color: transparent;
}

.list-for-update-delete {
  margin: 20px 15px;
}

.list-for-update-delete li {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
}

.comment-text-bubble {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 30rem;
  margin-right: 10px;
  min-width: 3rem;
}

.trash-comment {
  min-width: 50%;
}

.the-comments-commented {
  min-width: 50%;
}

.dropdown-container {
  display: inline-block;
  position: relative;
}

.dropdown-container .dropbtn-update-delete {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.dropdown-update-delete-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  border-radius: 10px;
  padding: 5px 10px;
  right: 0;
  top: 30px;
}

.dropdown-update-delete-content div {
  margin: 5px 0;
}

.fas.fa-trash-alt {
  cursor: pointer;
}

.hide-dropdown-btn:focus~.dropdown-container .dropdown-update-delete-content {
  display: none;
}

.show-dropdown-btn:focus~.dropdown-container .dropdown-update-delete-content {
  display: block;
}

.username-unfollow-follow {
  justify-content: space-between;
}

.button-follow,
.button-unfollow {
  color: #ffffff;
  font-size: 1rem;
  background-color: #0a1b40;
  border-radius: 68px;
  cursor: pointer;
  padding: 5px 22px;
  display: inline-block;
  position: relative;
}

.button-follow::before,
.button-unfollow::before {
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  left: 7px;
  top: 45%;
  transform: translateY(-50%);
}

.button-unfollow {
  color: white;
  background-color: #5264f0;
}

.button-follow:focus,
.button-unfollow:focus {
  outline: none;
}

.button-follow:active,
.button-unfollow:active {
  box-shadow: none;
  transform: translateY(1px);
}

.trash-comment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

i.fa.fa-pen {
  color: gray;
}
