.button-sign-up {
    background-color: #5308F4;
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 5px;
    height: 48px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 5px;
}

.signup-button {
    height: 40px;
}

.form-input {
    border-radius: .5rem;
}
