#settings-page {
    max-width: 33rem;
    margin: 0 auto;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 3rem;
    background-color: white;
}

#username {
    margin-left: 2rem;
    font-size: 1.3rem;
    font-weight: bold;
    position: absolute;
    z-index: 1;
}

#change-appearence-button {
    margin-left: 20rem;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

#profile-banner {
    width: 100%;
    height: 20rem;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

#profile-picture {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: #f0f0f0;
    z-index: 1;
    position: absolute;
    margin-left: 13.5rem;
    margin-top: -5rem;
}

#id {
    margin: 0 auto;
}