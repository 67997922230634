.results-of-search, .no-results {
  max-width: 35rem;
  margin: 0 auto;
  font-family: Helvetica, Arial, sans-serif;
}

.sort-container {
  display: inline-flex;
  align-items: baseline;
  flex-direction: column;
  border-radius: 5px black round;
   width: 69%;
}

.search-bar {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  width: 33%;
  max-width: 160px;
  margin-right: 30px;
  position: fixed;
  left: 65%;
  margin-top: 1rem;
}

.spork-search-bar {
  width: 180%;
  height: 2rem;
}

.footer {
  margin-right: 30px;
  position: fixed;
  left: 65%;
  color:white;
  margin-top: 3.2rem;
  height: 29%;
}

.footer-title {
  margin-left: 2rem;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 20rem;
}

.user-icon {
  font-size: 2rem;
}

header {
  display: inline-block;
}