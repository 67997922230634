@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,500);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
  border-radius: 20px
}


.sign-up-form-below-frog{
  display: flex;
  background-color: rgb(140, 105, 246);
  flex-direction: column;
  align-items: center;
  color: white;
  height: 384px;
  margin-top: -10px;
  width: 600px;
}


.form-sign-up {
  display: flex;
  flex-direction: column;
  margin-top: -29px;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
}

.email-and-password-form{
  background-color: rgb(211, 176, 244);

}

.frog-sign-up-video{
  display: flex;
}

.form-signup{
  display: flex;
  flex-direction: column;
}


.signup-link-in-login-form{
    text-align: center;
    padding-top: 5px;
}

.button-sign-up {
    background-color: #5308F4;
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 5px;
    height: 48px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 5px;
}

.signup-button {
    height: 40px;
}

.form-input {
    border-radius: .5rem;
}

.create-post-nav {
  width: 500px;
  margin: 0 auto;
  padding: 11px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .create-post-nav form {
    display: flex;
    flex-direction: column;
  }

  .create-post-username-gear {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .create-post-username {
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
  }

  .create-post-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -10px;
    border: none;
    outline: none;
  }

  .create-post-textarea {
    font-size: 14px;
    line-height: 1.5;
    border: none;
    outline: none;
    height: 80px;
    width: 450px;
    max-width: 450px;
    min-width: 450px;
    min-height: 80px;
    max-height: 80px;
  }

  .create-post-hashtag {
    font-size: 10px;
    /* margin-top: -2px; */
    border: none;
    outline: none;
  }

  .create-post-close-for-everyone-post-now-button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }



  .create-post-for-everyone-post-now-button {
    display: flex;
    align-items: center;
list-style: none;
  }

  .create-post-for-everyone-button,
  .create-post-post-now-button {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(190, 184, 184);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .create-post-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 160px;
    z-index: 1;
    list-style: none;
  }

  .create-post-for-everyone-button:hover + .create-post-dropdown,
  .create-post-post-now-button:hover + .create-post-dropdown {
    display: block;
    list-style: none;
  }

  .create-post-dropdown li a {
    display: block;
    padding: 10px;
    font-size: 18px;
    color: #333;
    text-decoration: none;
  }

  .create-post-dropdown li a:hover {
    background-color: #f1f1f1;
  }
  .create-post-icons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .create-post-icons span {
    font-size: 20px;
    margin-right: 10px;

  }
  .fa-image {
    color: rgb(241, 110, 70);
    font-size: 25px;
  }

  .fa-gif {
    color: yellow;
    font-size: 25px;
  }

  .fa-link {
    color:  rgb(72, 195, 127);
    font-size: 25px;
  }

  .fa-headphones {
    color: rgb(114, 69, 176);
    font-size: 25px;
  }

  .fa-video {
    color:rgb(224, 66, 245);
    font-size: 25px;
  }

  .fa-square-poll-vertical {
    color: gray;
    font-size: 25px;
  }

  li {
    list-style: none;
  }
  .create-post-post-now-button {
    list-style: none;
    font-size: 13px;
    color: white;
    background-color: gray;
    border: none;
    outline: none;
    cursor: pointer;
    height: 2rem;
    width: 5.5rem;

  }

  .create-post-close-button {
  list-style: none;
    font-size: 13px;
    color: white;
    background-color: gray;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    height: 2rem;
    width: 3.5rem;
    ;
  }


.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.post-header img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-item-postTitle {
  font-weight: bold;
  font-size: 1.6rem;
}

.post-item-postOwner-username {
  font-weight: 500;
}

.post-header .username {
  font-weight: bold;
  margin-right: 10px;
}

.post-header .timestamp {
  color: #666;
}

.comment-text-bubble {
  overflow-wrap: break-word;
}

.comment-owner {
  font-weight: bold;
}

.post-content {
  margin-bottom: 10px;
  overflow-wrap: break-word;
  font-size: 1.2rem;
}

.post-footer {
  display: flex;
  align-items: center;
}

.myList {
  display: none;
}

.post-footer button {
  border: none;
  background: none;
  font-weight: bold;
  color: #999;
  margin-right: 10px;
}

.post-footer button:hover {
  cursor: pointer;
  color: #333;
}

.hidden {
  display: none;
}

.post-option {
  box-sizing: border-box;
  background: white;
  min-height: 100px;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 5px;
  display: flex;
  position: relative;
  font-size: 30px;
}

.post-option ul {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex
}

.post-option ul li {
  width: 100%;
  list-style-type: none
}

.post-option ul svg {
  margin-bottom: 5px
}

.text-area-for-comments {
  display: block;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
  background-color: transparent;
}

.list-for-update-delete {
  margin: 20px 15px;
}

.list-for-update-delete li {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
}

.comment-text-bubble {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 30rem;
  margin-right: 10px;
  min-width: 3rem;
}

.trash-comment {
  min-width: 50%;
}

.the-comments-commented {
  min-width: 50%;
}

.dropdown-container {
  display: inline-block;
  position: relative;
}

.dropdown-container .dropbtn-update-delete {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.dropdown-update-delete-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  border-radius: 10px;
  padding: 5px 10px;
  right: 0;
  top: 30px;
}

.dropdown-update-delete-content div {
  margin: 5px 0;
}

.fas.fa-trash-alt {
  cursor: pointer;
}

.hide-dropdown-btn:focus~.dropdown-container .dropdown-update-delete-content {
  display: none;
}

.show-dropdown-btn:focus~.dropdown-container .dropdown-update-delete-content {
  display: block;
}

.username-unfollow-follow {
  justify-content: space-between;
}

.button-follow,
.button-unfollow {
  color: #ffffff;
  font-size: 1rem;
  background-color: #0a1b40;
  border-radius: 68px;
  cursor: pointer;
  padding: 5px 22px;
  display: inline-block;
  position: relative;
}

.button-follow::before,
.button-unfollow::before {
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  left: 7px;
  top: 45%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.button-unfollow {
  color: white;
  background-color: #5264f0;
}

.button-follow:focus,
.button-unfollow:focus {
  outline: none;
}

.button-follow:active,
.button-unfollow:active {
  box-shadow: none;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.trash-comment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

i.fa.fa-pen {
  color: gray;
}

.update-post-nav {
    width: 500px;
    margin: 0 auto;
    padding: 11px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.update-post-nav form {
    display: flex;
    flex-direction: column;
}

.update-post-username-gear {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.update-post-username {
    font-weight: bold;
    margin-right: 10px;
}

.update-post-title {
    margin-left: -8px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -6px;
    border: none;
    outline: none;
    margin-top: -8px;
}


.update-post-textarea {
    /* font-size: 14px;
    line-height: 1.5;
    border: none;
    outline: none;
    height: 80px;
    width: 450px; */
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid #c3bfbf85;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    height: 80px;
    width: 450px;

}

.update-post-hashtag {
    font-size: 10px;
    /* margin-top: -2px; */
    border: none;
    outline: none;
    margin-left: 0;
    padding-left: 0;
}

.update-post-close-for-everyone-post-now-button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.update-post-close-button {
list-style: none;
    font-size: 13px;
    color: white;
    background-color: gray;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    height: 2.5em;
    width: 3rem;
    ;
}

.update-post-for-everyone-post-now-button {
    display: flex;
    align-items: center;
    list-style: none;
}

.update-post-for-everyone-button,
.update-post-post-now-button {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(190, 184, 184);
    border: none;
    outline: none;
    cursor: pointer;
}

.update-post-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 160px;
    z-index: 1;
    list-style: none;
}

.update-post-for-everyone-button:hover+.update-post-dropdown,
.update-post-post-now-button:hover+.update-post-dropdown {
    display: block;
    list-style: none;
}

.update-post-dropdown li a {
    display: block;
    padding: 10px;
    font-size: 18px;
    color: #333;
    text-decoration: none;
}

.update-post-dropdown li a:hover {
    background-color: #f1f1f1;
}

.update-post-icons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.update-post-icons span {
    font-size: 20px;
    margin-right: 10px;

}

.fa-image {
    color: rgb(241, 110, 70);
    font-size: 25px;
}

.fa-gif {
    color: yellow;
    font-size: 25px;
}

.fa-link {
    color: rgb(72, 195, 127);
    font-size: 25px;
}

.fa-headphones {
    color: rgb(114, 69, 176);
    font-size: 25px;
}

.fa-video {
    color: rgb(224, 66, 245);
    font-size: 25px;
}

.fa-square-poll-vertical {
    color: gray;
    font-size: 25px;
}

.update-post-post-now-button {
    list-style: none;
}

li {
    list-style: none;
}

/* input:valid+label {
    color: #ff5a5f;
}

input:focus {
    outline: none;
} */


.delete-post-title {
    font-weight: bold
}

.form-div {
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
}
input {
    width: 100%;
    height: 45px;
    border: none;
    background-color: #fafafa;
    padding: 0 10px;
    font-size: 14px;
}
.delete-post-submit-button {
    background-color: #f40857;
    width: 12rem;
    height: 2.5rem;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
}

.delete-post-submit-button:hover {
    background-color:#c20349;
}



.delete-post-cancel-button {
    /* width: 132px;
    width: 100%;
    height: 50px; */
    width: 12rem;
    height: 2.5rem;
    background-color: rgb(152, 151, 151);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    margin: 10px;
}

.delete-post-cancel-button:hover {
    background-color: rgb(110, 110, 110);
}

form.create-post-area-comments-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-post-textarea-comments {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  width: 370px;
  resize: none;
  margin-bottom: 10px;
}

button[type="submit"] {
  /* background-color: #5308F4;
  height: 30px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer; */
  background-color: #216ba5;
    height: 2.5rem;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    /* font-size: .8rem; */
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
}

button[type="submit"]:hover {
  background-color: #1b4c5f;
}

form.create-post-textarea-comments-form {
  display: flex;
}

.submit-comment {
  background-color: #0a1b40;
  border: none;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 13px; */
  margin-bottom: 10px;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.user-picture-comments {
  display: flex;
}

.user-picture-comments {
  display: flex;
  align-items: center;
}

.user-picture-comments img {
  margin-right: 10px;
  height: 45px;
  border-radius: 35px;
}

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


:root {
  --bg: #e3e4e8;
  --fg: #2e3138;
  --primary: #255ff4;

}

/*
body {
  background: var(--bg);
  display: grid;
  font: 1em/1.5 sans-serif;
  height: 100vh;
  place-items: center;
} */

.hourglass,
.hourglass:before,
.hourglass:after {
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.hourglass {
  --polygonH: polygon(0% 0%, 100% 0%, 100% 5.55%, 95% 5.55%, 95% 28%, 60% 46%, 60% 54%, 95% 72%, 95% 94.45%, 100% 94.45%, 100% 100%, 0% 100%, 0% 94.45%, 5% 94.45%, 5% 72%, 40% 54%, 40% 46%, 5% 28%, 5% 5.55%, 0% 5.55%);
  -webkit-animation-name: flip;
          animation-name: flip;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  background-image: linear-gradient(#255ff4 0.5em, #737a8c55 0.5em 8.5em, #255ff4 8.5em);
  background-image: linear-gradient(var(--primary) 0.5em, #737a8c55 0.5em 8.5em, var(--primary) 8.5em);
  clip-path: var(--polygonH);
  -webkit-clip-path: var(--polygonH);
  overflow: hidden;
  width: 5em;
  height: 9em;
  z-index: 0;
  margin: 10% 47%;
}

.hourglass:before,
.hourglass:after {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  content: "";
  display: block;
  position: absolute;
}

.hourglass:before {
  --polygonB1: polygon(0% 0%, 100% 0%, 100% 24%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 0% 24%);
  --polygonB2: polygon(0% 4%, 100% 4%, 100% 24%, 55% 45%, 55% 100%, 55% 100%, 55% 100%, 45% 100%, 45% 100%, 45% 100%, 45% 45%, 0% 24%);
  --polygonB3: polygon(0% 24%, 100% 24%, 100% 24%, 55% 45%, 55% 80%, 100% 100%, 100% 100%, 0% 100%, 0% 100%, 45% 80%, 45% 45%, 0% 24%);
  --polygonB4: polygon(45% 45%, 55% 45%, 55% 45%, 55% 45%, 55% 58%, 100% 76%, 100% 100%, 0% 100%, 0% 76%, 45% 58%, 45% 45%, 45% 45%);
  --polygonB5: polygon(50% 53%, 50% 53%, 50% 53%, 50% 53%, 50% 53%, 100% 76%, 100% 100%, 0% 100%, 0% 76%, 50% 53%, 50% 53%, 50% 53%);
  -webkit-animation-name: fill;
          animation-name: fill;
  background-color: #2e3138;
  background-color: var(--fg);
  background-size: 100% 3.6em;
  clip-path: var(--polygonB1);
  -webkit-clip-path: var(--polygonB1);

  left: 0.5em;
  width: 4em;
  height: 8em;
  z-index: 1;
}

.hourglass:after {
  -webkit-animation-name: glare;
          animation-name: glare;
  background:
    linear-gradient(90deg, #0000 0.5em, #0003 0.5em 1.5em, #0000 1.5em 3.5em, #fff3 3.5em 4.5em, #fff0 4.5em 6.5em, #0003 6.5em 7.5em, #0000 7.5em) 0 0 / 100% 0.5em,
    linear-gradient(90deg, #0000 0.75em, #0003 0.75em 1.25em, #0000 1.25em 3.75em, #fff3 3.75em 4.25em, #fff0 4.25em 6.75em, #0003 6.75em 7.25em, #0000 7.25em) 0 0.5em / 100% 8em,
    linear-gradient(90deg, #0000 0.5em, #0003 0.5em 1.5em, #0000 1.5em 3.5em, #fff3 3.5em 4.5em, #fff0 4.5em 6.5em, #0003 6.5em 7.5em, #0000 7.5em) 0 100% / 100% 0.5em;
  background-repeat: repeat-x;
  left: -3em;
  width: 200%;
  height: 100%;
  z-index: 2;
}

/* Animations */
@-webkit-keyframes fill {
  from {
    clip-path: var(--polygonB1);
    -webkit-clip-path: var(--polygonB1);
  }

  10% {
    clip-path: var(--polygonB2);
    -webkit-clip-path: var(--polygonB2);
  }

  45% {
    clip-path: var(--polygonB3);
    -webkit-clip-path: var(--polygonB3);
  }

  80% {
    clip-path: var(--polygonB4);
    -webkit-clip-path: var(--polygonB4);
  }

  85%,
  to {
    clip-path: var(--polygonB5);
    -webkit-clip-path: var(--polygonB5);
  }
}
@keyframes fill {
  from {
    clip-path: var(--polygonB1);
    -webkit-clip-path: var(--polygonB1);
  }

  10% {
    clip-path: var(--polygonB2);
    -webkit-clip-path: var(--polygonB2);
  }

  45% {
    clip-path: var(--polygonB3);
    -webkit-clip-path: var(--polygonB3);
  }

  80% {
    clip-path: var(--polygonB4);
    -webkit-clip-path: var(--polygonB4);
  }

  85%,
  to {
    clip-path: var(--polygonB5);
    -webkit-clip-path: var(--polygonB5);
  }
}

@-webkit-keyframes glare {

  from,
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(3em);
            transform: translateX(3em);
  }
}

@keyframes glare {

  from,
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(3em);
            transform: translateX(3em);
  }
}

@-webkit-keyframes flip {

  from,
  90% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@keyframes flip {

  from,
  90% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: #17181c;
    --fg: #c7cad1;
  }
}

z#root {
  background-color: #0a1b40;
}

.Feed {
  max-width: 35rem;
  margin: 0 auto;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-left: auto;
}

.posts {
  margin-top: 1rem;
  width: 100%;
}

.loading {
  margin-top: 12rem;
  margin-left: -7rem;
}

.post {
  background-color: #fff;
  border: 1px solid #dddfe2;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.AA-Text {
  text-decoration: none;
}

/* Reset the list styles and remove default button styles */
.post-types {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  box-sizing: border-box;
  height: 85px;
  width: 560px;
  background-color: white;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  border-radius: 5px;
}

.post-type-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #fff;
}

.post-type-btn:focus {
  outline: none;
}

.text-btn {
  color: #35465c;
  display: flex;
  flex-direction: column;
}

.photo-btn {
  color: #fc4c4c;
  display: flex;
  flex-direction: column;
}

.quote-btn {
  color: #ff8b4e;
  display: flex;
  flex-direction: column;
}

.link-btn {
  color: #3cb878;
  display: flex;
  flex-direction: column;
}

.chat-btn {
  color: #3a81c3;
  display: flex;
  flex-direction: column;
}

.audio-btn {
  color: #a07dfc;
  display: flex;
  flex-direction: column;
}

.video-btn {
  color: #e1306c;
  display: flex;
  flex-direction: column;
}

.post-type-icon {
  margin-right: 8px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.following-foryou-tags-manage {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: transparent;
  margin-left: 22px;
  margin-bottom: -32px;
}

.following-foryou-tags-manage a {
  margin: 0 8px;
  color: rgb(46, 166, 226);
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 17px;
}

.following-foryou-tags-manage a:hover {
  text-decoration: underline;
}

.create-please {
  background: transparent;
  border: none;
}

.create-footer,
.photo-footer,
.quote-footer,
.link-footer,
.chat-footer,
.sound-footer,
.video-footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.photo-footer {
  color: rgb(211, 58, 58);
}

.quote-footer {
  color: orange;
}

.chat-footer {
  color: blue;
}

.sound-footer {
  color: rgb(151, 118, 182);
}

.link-footer {
  color: rgb(72, 195, 127);
}

.video-footer {
  color: rgb(224, 66, 245);
}

.follow-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AllLikes {
    max-width: 600px;
    margin: 0 auto;
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.post {
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.timestamp-2, .post-content-2, .post-item-postTitle-2 {
    margin-left: 10px;
}

.post-footer-2 {
    margin-left: 6px;
}
.post2 {
    background-color: #fff;
        /* border: 1px solid #dddfe2; */
        border-radius: 5px;
        /* padding: 16px 16px 0 16px; */
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.no-likes {
    color: white;
}

#root {
    background-color: #0a1b40;
}

.current-user-feed {
    max-width: 600px;
    margin: 0 auto;
    font-family: Helvetica, Arial, sans-serif;
}


.current-post {
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 5px;
    padding: 16px;
    list-style: none;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.current-user-nav-links-list {
    position: absolute;
    left: 70%;
    bottom: 42%;
}

.current-user-nav-links-list ul li {
    padding: 4px 4px 4px 4px;
    margin-bottom: 10px;
    text-decoration: none;
    list-style: none;
    color: white;
    outline: none;
    border-radius: 1px;
    font-size: 20px;

}

.current-user-nav-links-list ul li:hover {
    background-color: gray;
}

.current-user-nav-links-list ul {
    text-decoration: none;
}

.current-user-nav-links-list {
    text-decoration: none;
    margin: -48px;
}

a
{
    text-decoration: none;
}

.results-of-search, .no-results {
  max-width: 35rem;
  margin: 0 auto;
  font-family: Helvetica, Arial, sans-serif;
}

.sort-container {
  display: inline-flex;
  align-items: baseline;
  flex-direction: column;
  border-radius: 5px black round;
   width: 69%;
}

.search-bar {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  width: 33%;
  max-width: 160px;
  margin-right: 30px;
  position: fixed;
  left: 65%;
  margin-top: 1rem;
}

.spork-search-bar {
  width: 180%;
  height: 2rem;
}

.footer {
  margin-right: 30px;
  position: fixed;
  left: 65%;
  color:white;
  margin-top: 3.2rem;
  height: 29%;
}

.footer-title {
  margin-left: 2rem;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 20rem;
}

.user-icon {
  font-size: 2rem;
}

header {
  display: inline-block;
}

@-webkit-keyframes rocket-movement {100% {-webkit-transform: translate(1200px,-600px); } }
@keyframes rocket-movement { 100% {-webkit-transform: translate(1200px,-600px);transform: translate(1200px,-600px);} }
@-webkit-keyframes spin-earth { 100% { -webkit-transform: rotate(-360deg); transition: -webkit-transform 20s; transition: transform 20s; transition: transform 20s, -webkit-transform 20s;  } }
@keyframes spin-earth{ 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); transition: -webkit-transform 20s; transition: transform 20s; transition: transform 20s, -webkit-transform 20s; } }
@-webkit-keyframes move-astronaut {
    100% { -webkit-transform: translate(-160px, -160px);}
}
@keyframes move-astronaut{
    100% { -webkit-transform: translate(-160px, -160px); transform:translate(-160px, -160px); }
}
@-webkit-keyframes rotate-astronaut {
    100% { -webkit-transform: rotate(-720deg);}
}
@keyframes rotate-astronaut{
    100% { -webkit-transform: rotate(-720deg); transform:rotate(-720deg); }
}
@-webkit-keyframes glow-star {
    40% { -webkit-opacity: 0.3;}
    90%,100% { -webkit-opacity: 1; -webkit-transform: scale(1.2);}
}
@keyframes glow-star{
    40% { -webkit-opacity: 0.3; opacity: 0.3;  }
    90%,100% { -webkit-opacity: 1; opacity: 1; -webkit-transform: scale(1.2); transform: scale(1.2); border-radius: 999999px;}
}

.spin-earth-on-hover{

    transition: ease 200s !important;
    -webkit-transform: rotate(-3600deg) !important;
            transform: rotate(-3600deg) !important;
}


.bg-purple{
    background: url(http://salehriaz.com/404Page/img/bg_purple.png);
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    height: 100%;
    overflow: hidden;

}

.custom-navbar{
    padding-top: 15px;
}

.brand-logo{
    margin-left: 25px;
    margin-top: 5px;
    display: inline-block;
}

.navbar-links{
    display: inline-block;
    float: right;
    margin-right: 15px;
    text-transform: uppercase;


}



.btn-request{
    padding: 10px 25px;
    border: 1px solid #FFCB39;
    border-radius: 100px;
    font-weight: 400;
}

.btn-request:hover{
    background-color: #FFCB39;
    color: #fff;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0,0,0,0.1);
}

.btn-go-home{
    position: relative;
    z-index: 200;
    margin: 15px auto;
    width: 100px;
    padding: 10px 15px;
    border: 1px solid #FFCB39;
    border-radius: 100px;
    font-weight: 400;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing : 2px;
    font-size: 11px;
    transition: all 0.3s ease-in;
}

.btn-go-home:hover{
    background-color: #FFCB39;
    color: #fff;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0,0,0,0.1);
}

.central-body{
/*    width: 100%;*/
    padding: 17% 5% 10% 5%;
    text-align: center;
}

.objects img{
    z-index: 90;
    pointer-events: none;
}

.object_rocket{
    z-index: 95;
    position: absolute;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    top: 75%;
    pointer-events: none;
    -webkit-animation: rocket-movement 200s linear infinite both running;
            animation: rocket-movement 200s linear infinite both running;
}

.object_earth{
    position: absolute;
    top: 20%;
    left: 15%;
    z-index: 90;
/*    animation: spin-earth 100s infinite linear both;*/
}

.object_moon{
    position: absolute;
    top: 12%;
    left: 25%;
/*
    transform: rotate(0deg);
    transition: transform ease-in 99999999999s;
*/
}

.earth-moon{

}

.object_astronaut{
    -webkit-animation: rotate-astronaut 200s infinite linear both alternate;
            animation: rotate-astronaut 200s infinite linear both alternate;
}

.box_astronaut{
    z-index: 110 !important;
    position: absolute;
    top: 60%;
    right: 20%;
    will-change: transform;
    -webkit-animation: move-astronaut 50s infinite linear both alternate;
            animation: move-astronaut 50s infinite linear both alternate;
}

.image-404{
    position: relative;
    z-index: 100;
    pointer-events: none;
}

.stars{
    background: url(http://salehriaz.com/404Page/img/overlay_stars.svg);
    background-repeat: repeat;
    background-size: contain;
    background-position: left top;
}

.glowing_stars .star{
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    width: 3px;
    height: 3px;
    opacity: 0.3;
    will-change: opacity;
}

.glowing_stars .star:nth-child(1){
    top: 80%;
    left: 25%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 1s;
            animation: glow-star 2s infinite ease-in-out alternate 1s;
}
.glowing_stars .star:nth-child(2){
    top: 20%;
    left: 40%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 3s;
            animation: glow-star 2s infinite ease-in-out alternate 3s;
}
.glowing_stars .star:nth-child(3){
    top: 25%;
    left: 25%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 5s;
            animation: glow-star 2s infinite ease-in-out alternate 5s;
}
.glowing_stars .star:nth-child(4){
    top: 75%;
    left: 80%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 7s;
            animation: glow-star 2s infinite ease-in-out alternate 7s;
}
.glowing_stars .star:nth-child(5){
    top: 90%;
    left: 50%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 9s;
            animation: glow-star 2s infinite ease-in-out alternate 9s;
}

@media only screen and (max-width: 600px){
    .navbar-links{
        display: none;
    }

    .custom-navbar{
        text-align: center;
    }

    .brand-logo img{
        width: 120px;
    }

    .box_astronaut{
        top: 70%;
    }

    .central-body{
        padding-top: 25%;
    }
}


.modal-open-tab{
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 2em;
    background: white;
}

.categoriesSideMenu {
    color: #ACB4BD;
}

.button-green-login {
    color: white;
}

.login-sidemenu {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sporkLogoSideMenu {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.side-menu-container {
    flex-basis: 20%;
    margin-right: 5%
}

.homeIcon .accountDropdown {
    padding: 8px 16px;
}

.sideMenu {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    width: 33%;
    max-width: 160px;
    height: 100vh;
    margin-right: 30px;
    position: fixed;
    left: 26%;
}

.sideIcon {
    width: 14px;
    margin-right: 5px;
}

.homeDropdown {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.following-account {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeDropdown:hover, .accountDropdown:hover, .likes-account:hover,
.following-account:hover, .logOutButton-account:hover
  {
    background-color: #122943;
    cursor: pointer;
}

.accountDropdown {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.account {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
}

.account-container {
    border-top: 2px solid #21374F;
}

.createPostButtonContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.createPostButton {
    padding: 8px 16px;
    font-size: 1rem;
    border-radius: 40px;
    width: 100%;
}

.createPostButtonContainer > .hello {
    width: 100%;
}

.side-menu-container {
    max-height: 900px;
}

.createPostButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    background-color: #00b8ff;
    grid-gap: 2px;
    gap: 2px;
}

.createPostButtonContainer {
    display: flex;
    align-items: center;
}

/* #122943 for hovering over sidemenu */

.login-button-modal {
    color: white;
}
.log-in-form-below-frog {
  display: flex;
  background-color: rgb(140, 105, 246);
  flex-direction: column;
  align-items: center;
  color: white;
  height: 300px;
  margin-top: -10px;
}

.login-button {
  height: 30px;
  font-size: 2rem;
  background-color: red;
}

.demo-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  /* height: 50px; */
  border: none;
  border-radius: 5px;
  height: 35px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 5px;
}

.loginform-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  height: 48px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 5px;
}

.demo-sign-in-button:hover,
.loginform-sign-in-button:hover {
  background-color: #00b8ff;
}

.signup-link-in-login-form {
  justify-content: space-between;
}

.form-log-in {
  display: flex;
  flex-direction: column;
  color: white;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
}

.email-and-password-form {
  background-color: rgb(211, 176, 244);
  border-radius: .5rem;
}

.error-messages-login > .error-message {
  font-weight: bold;
}

.login-button {
  height: 30px;
  padding: 0px;
  font-size: 1rem;
}

.log-in-form-below-frog {
  display: flex;
  background-color: rgb(140, 105, 246);
  flex-direction: column;
  align-items: center;
  color: white;
  height: 300px;
  margin-top: -10px;
}

/* .demo-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  height: 48px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 5px;

} */

.loginform-sign-in-button {
  background-color: #5308F4;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  /* height: 52px; */
  height: 35px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: .1rem;
  margin-left: 5px;
}

.signup-link-in-login-form {
  justify-content: space-between;

}

.form-log-in {
  display: flex;
  flex-direction: column;
  color: white;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;

}

.email-and-password-form {
  background-color: rgb(211, 176, 244);
  border-radius: .5rem;
}



.sign-up-button {
  color: white
}

.loginform-sign-in-button .hello {
  color: white;
}

.hi {
  background-color: reds;
}

#settings-page {
    max-width: 33rem;
    margin: 0 auto;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 3rem;
    background-color: white;
}

#username {
    margin-left: 2rem;
    font-size: 1.3rem;
    font-weight: bold;
    position: absolute;
    z-index: 1;
}

#change-appearence-button {
    margin-left: 20rem;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

#profile-banner {
    width: 100%;
    height: 20rem;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

#profile-picture {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: #f0f0f0;
    z-index: 1;
    position: absolute;
    margin-left: 13.5rem;
    margin-top: -5rem;
}

#id {
    margin: 0 auto;
}
/* TODO Add site wide styles */
