.update-post-nav {
    width: 500px;
    margin: 0 auto;
    padding: 11px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.update-post-nav form {
    display: flex;
    flex-direction: column;
}

.update-post-username-gear {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.update-post-username {
    font-weight: bold;
    margin-right: 10px;
}

.update-post-title {
    margin-left: -8px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -6px;
    border: none;
    outline: none;
    margin-top: -8px;
}


.update-post-textarea {
    /* font-size: 14px;
    line-height: 1.5;
    border: none;
    outline: none;
    height: 80px;
    width: 450px; */
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid #c3bfbf85;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    height: 80px;
    width: 450px;

}

.update-post-hashtag {
    font-size: 10px;
    /* margin-top: -2px; */
    border: none;
    outline: none;
    margin-left: 0;
    padding-left: 0;
}

.update-post-close-for-everyone-post-now-button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.update-post-close-button {
list-style: none;
    font-size: 13px;
    color: white;
    background-color: gray;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    height: 2.5em;
    width: 3rem;
    ;
}

.update-post-for-everyone-post-now-button {
    display: flex;
    align-items: center;
    list-style: none;
}

.update-post-for-everyone-button,
.update-post-post-now-button {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(190, 184, 184);
    border: none;
    outline: none;
    cursor: pointer;
}

.update-post-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 160px;
    z-index: 1;
    list-style: none;
}

.update-post-for-everyone-button:hover+.update-post-dropdown,
.update-post-post-now-button:hover+.update-post-dropdown {
    display: block;
    list-style: none;
}

.update-post-dropdown li a {
    display: block;
    padding: 10px;
    font-size: 18px;
    color: #333;
    text-decoration: none;
}

.update-post-dropdown li a:hover {
    background-color: #f1f1f1;
}

.update-post-icons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.update-post-icons span {
    font-size: 20px;
    margin-right: 10px;

}

.fa-image {
    color: rgb(241, 110, 70);
    font-size: 25px;
}

.fa-gif {
    color: yellow;
    font-size: 25px;
}

.fa-link {
    color: rgb(72, 195, 127);
    font-size: 25px;
}

.fa-headphones {
    color: rgb(114, 69, 176);
    font-size: 25px;
}

.fa-video {
    color: rgb(224, 66, 245);
    font-size: 25px;
}

.fa-square-poll-vertical {
    color: gray;
    font-size: 25px;
}

.update-post-post-now-button {
    list-style: none;
}

li {
    list-style: none;
}
